import React, { useEffect } from "react"
import { useParams } from "react-router-dom";
import { observer } from "mobx-react"
import { CssBaseline } from "@material-ui/core";
import { useAuthStores } from "stores/useStores";
import ToastContainer from "common/components/atoms/ToastContainer.js";
import DialogContainer from "common/components/atoms/DialogContainer.js";
import _ from "lodash";

const DefaultLayout = observer(({ history, children }) => {
    const { authStore } = useAuthStores();
    const { isLogin, isExpire } = authStore;
    const { hashcode } = useParams();

    useEffect(() => {
        if (_.isEmpty(hashcode) === false) {
            authStore.setValue("hashcode", hashcode);
        }
        authStore.authExpireCheck({ history });
        authStore.authCheck({ history });
        console.log("로그인여부 : " + isLogin);
    }, [isLogin, isExpire, hashcode, authStore, history])

    if (isLogin) {
        return (
            <React.Fragment>
                <CssBaseline />
                <ToastContainer />
                <DialogContainer />
                <div className="root">
                    <div className={"dimBg"}></div>
                    <div className="data">
                        {children}
                    </div>
                </div>
            </React.Fragment >
        );
    } else {
        return _.includes(["/login", "/expire"], window.location.pathname) && (
            <React.Fragment>
                <CssBaseline />
                <ToastContainer />
                <div className="root">
                    <div className="data">
                        {children}
                    </div>
                </div>
            </React.Fragment>
        );
    }

});


export default DefaultLayout;