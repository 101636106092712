import React, { useEffect, useState, useRef } from "react";
import dialogEventMaganer from "common/components/atoms/utils/dialogEventMaganer";
import classnames from "classnames";
import CustomButton from "common/components/atoms/CustomButton";
import { Grid } from "@material-ui/core";
import Modal from "common/components/atoms/Modal";
import "styles/atoms.scss";

const DialogContainer = () => {
    const [dialog, setDialog] = useState([]);
    const dialogRef = useRef();

    //다이얼로그 - 확인 버튼 클릭
    const onAgree = e => {
        dialog.confirmCallback();
        onAlways();
        setDialog({ ...dialog, on: false });
    };

    //다이얼로그 - 취소 버튼 클릭
    const onClose = e => {
        dialog.cancelCallback();
        onAlways();
        setDialog({ ...dialog, on: false });
    };

    //다이얼로그 - 버튼 실행후 무조건 실행되는 함수
    const onAlways = e => {
        dialog.alwaysCallback();
        setDialog({ ...dialog, on: false });
    };

    //다이얼로그 - 뒷 배경 클릭
    const onCloseDialog = e => {
        setDialog({ ...dialog, on: false });
    };

    useEffect(_ => {
        dialogEventMaganer.on(0, (args) => {
            if (dialogRef) {
                appendDialog(args);
            }
        });

        return _ => dialogEventMaganer.off(0);
    });

    const appendDialog = (args) => {
        setDialog({ on: true, ...args });
    };

    //다이얼로그 - 내용
    const body = (
        <Grid item xs={12} sm={12} className="cDialog">
            <h4 className="title">{dialog.title}</h4>
            <div className="con">
                {dialog.contents}
            </div>
            <div className="cButton">
                <CustomButton
                    title={dialog.closeLabel}
                    color="secondary"
                    variant="outlined"
                    onClick={onClose}
                    classes={classnames({
                        visible: true
                    })}
                />
                <CustomButton
                    title={dialog.confirmLabel}
                    color="primary"
                    variant="contained"
                    onClick={onAgree}
                    classes={classnames({
                        visible: true
                    })}
                />
            </div>
        </Grid>
    )

    return (
        <div className={classnames("rev-dialog-root", {
            on: dialog.length === 0 ? false : dialog.on
        })} ref={dialogRef}>
            <Modal
                open={dialog.length === 0 ? false : dialog.on}
                onClickCloseModal={onCloseDialog}
                body={body}
                width={dialog.width !== undefined ? dialog.width : "315px"}
                height={dialog.height !== undefined ? dialog.height : ""}
                className="dialog"
            />
        </div>
    )
}

export default DialogContainer