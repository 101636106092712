import { observable } from "mobx";
import CollectRequest from "http/collect";
import toast from "common/components/atoms/Toast";
const collectRequest = new CollectRequest();

const collectStore = observable({
    schedules: [],          // 스케줄 리스트가 관리되는 상태값

    // 초기화
    init() {
    },
    // 학생 스케쥴 등록
    createSchedules(data, callback, errorCallback) {
        collectRequest.createSchedules(data, response => {
            console.log({ desc: "createSchedules", response });
            if (response.status === 200) {
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 학생 스케쥴 조회
    getSchedules(callback, errorCallback) {
        collectRequest.getSchedules(response => {
            console.log({ desc: "getSchedules", response });
            if (response.status === 200) {
                this.setValue("schedules", response.data.data);
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 학생 스케쥴 상세보기
    getSchedulesById({ schedule_id }, callback, errorCallback) {
        const param = { schedule_id };

        collectRequest.getSchedulesById(param, response => {
            console.log({ desc: "getSchedulesById", response });
            if (response.status === 200) {
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 학생 스케쥴 삭제
    deleteSchedules(id, callback, errorCallback) {
        collectRequest.deleteSchedules(id, response => {
            console.log({ desc: "deleteSchedules", response });
            if (response.status === 200) {
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 학원 강의시간표 조회 
    getClassesByWeekday({ academy_id, weekday }, callback, errorCallback) {
        const param = { academy_id, weekday };

        collectRequest.getClassesByWeekday(param, response => {
            console.log({ desc: "getClassesByWeekday", response });
            if (response.status === 200) {
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });

    },
    // 정류장 가져오기
    getStation({ academy_id, class_id, weekday, schedule_type }, callback, errorCallback) {
        const param = { academy_id, class_id, weekday, schedule_type };

        collectRequest.getStation(param, response => {
            console.log({ desc: "getStation", response });
            if (response.status === 200) {
                callback(response.data.data);
            } else {
                toast.error({ title: `[${response.data.code}] ${response.data.message}` }, { autoClose: 1500 });
            }

        }, error => {
            errorCallback(error);
        });
    },
    // 데이터 저장
    setInfo(info) {
        this.info = info
    },
    // 데이터 저장
    setValue(key, value) {
        this[key] = value;
    },
})
export { collectStore };