import React from "react"
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded"

import "styles/atoms.scss"

const Loading = (props) => {
    return (
        <div id="bgLoder">
            <HourglassEmptyRoundedIcon
                htmlColor={"#ffcd00"}
            />
        </div>
    )
}
export default Loading