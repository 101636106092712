import { authStore } from "./authStore";
import { collectStore } from "./collectStore";
import { mapStore } from "./mapStore";

const useAuthStores = () => {
    return { authStore };
};

const useCollectStores = () => {
    return { collectStore };
};

const useMapStores = () => {
    return { mapStore };
};

export { useAuthStores, useCollectStores, useMapStores };
