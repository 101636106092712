import axios from "axios"
import { createBrowserHistory } from "history";
import toast from "common/components/atoms/Toast"
export let history = createBrowserHistory({ basename: "" })

class Request {
    constructor() {
        this.token = ""
    }
    getHeader = (code, type = "application/json") => {
        let headers = {
            "Client-Type": "web",
            "Content-Type": type,
            "Access-Control-Allow-Origin": "*"
        };

        const access_token = window.sessionStorage.getItem("access_token");
        // console.log(access_token);
        if (access_token !== null && access_token !== "" && ["COMMON"].includes(code) === false) {
            this.token = "Bearer " + window.sessionStorage.getItem("access_token");
            headers["Authorization"] = (this.token !== undefined) ? this.token : "";
        }
        return headers;
    }

    executeError = (error) => {
        console.log(error)
        if (error.response !== undefined) {
            console.log(error.response.data)
            toast.error({ title: `[${error.response.data.code}] ${error.response.data.message}` }, { autoClose: 1500 })
            if (error.response.status === 401) {
                //toast.error({ title: `로그인 실패`, msg: `로그인 세션이 만료되었습니다.` }, { autoClose: "N" })
                window.sessionStorage.removeItem("access_token")
                history.push("/login")
            }
        }
    }

    getApiHost = (url) => {
        let arr = url.split("/")
        let apiHost = process.env.REACT_APP_API_HOST
        let code = "DEFAULT"

        // console.log(arr);

        if (arr.length > 0 && ["hashcode", "auth", "validation"].includes(arr[3])) {
            apiHost = process.env.REACT_APP_AUTH_API_HOST
            code = "AUTH"
        }

        return { apiHost, code }
    }

    get = (url, request) => {
        const rs = this.getApiHost(url)
        const apiHost = rs.apiHost
        let callback = request.callback
        let errorCallback = request.errorCallback
        let params = request.params
        axios.get(apiHost + url,
            {
                headers: this.getHeader(rs.code),
                params: (params !== undefined) ? params : {}
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                }
            } else {
                toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
            }
        })
    }

    post = (url, request, type = "application/json") => {
        const rs = this.getApiHost(url);
        const apiHost = rs.apiHost;
        let data = request.data;
        let callback = request.callback;
        let errorCallback = request.errorCallback;

        axios.post(apiHost + url,
            (data !== undefined) ? data : null,
            {
                headers: this.getHeader(rs.code, type)
            }
        ).then(response => {
            callback(response);
        }).catch((error) => {
            this.executeError(error);
            if (errorCallback !== undefined) {
                errorCallback(error);
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" });
                }
            } else {
                toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" });
            }
        });
    };

    put = (url, request) => {
        const rs = this.getApiHost(url)
        const apiHost = rs.apiHost
        let data = request.data
        let callback = request.callback
        let errorCallback = request.errorCallback
        axios.put(apiHost + url,
            (data !== undefined) ? data : null,
            {
                headers: this.getHeader(rs.code)
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                }
            } else {
                toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
            }
        })
    }

    del = (url, request) => {
        const rs = this.getApiHost(url)
        const apiHost = rs.apiHost
        let data = request.data
        let callback = request.callback
        let errorCallback = request.errorCallback
        axios.delete(apiHost + url,
            {
                data: (data !== undefined) ? data : {},
                headers: this.getHeader(rs.code)
            }
        ).then(response => {
            callback(response)
        }).catch((error) => {
            this.executeError(error)
            if (errorCallback !== undefined) {
                errorCallback(error)
            }
            if (error.response !== undefined) {
                if (error.response.status !== 401) {
                    toast.error({ title: "서버요청 오류가 발생했습니다 : error code[" + error.response.data.error_code + "]" }, { autoClose: "N" })
                }
            } else {
                toast.error({ title: "서버요청 오류가 발생했습니다." }, { autoClose: "N" })
            }
        })
    }
}


export default Request
