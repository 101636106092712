const dialogEventMaganer = {
    param: new Map(),
    on(event, callback) {
        this.param.has(event) || this.param.set(event, [])
        this.param.get(event).push(callback)

        return this
    },
    off(event) {
        this.param.delete(event)
        return this
    },
    open(event, args, confirmCallback, cancelCallback, alwaysCallback) {
        if (this.param.get(event) !== undefined) {
            this.param.get(event).forEach(callback => {
                const p = { ...args, confirmCallback, cancelCallback, alwaysCallback }
                callback.call(void 0, p)
            })

        }
    }
}

export default dialogEventMaganer