import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Layout } from "./pages/templates/Layout";
import Loading from "common/components/atoms/Loading";

const LazyApp = lazy(() => import("./App"))
ReactDOM.render(
    <Suspense fallback={<Loading />}>
        <Layout>
            <LazyApp />
        </Layout>
    </Suspense>
    ,
    document.getElementById("root")
);
reportWebVitals();
