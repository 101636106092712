import { observable } from "mobx";
// import ScheduleRequest from "http/schedule";
// const scheduleRequest = new ScheduleRequest();

const mapStore = observable({
    info: {},
    activeMarker: "",     // 사용자가 직접선택한 마커
    //초기화
    init() {
    },
    //데이터 저장
    setValue(key, value) {
        this[key] = value;
    },
})
export { mapStore };