import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import "styles/atoms.scss"
import closeIcon from "img/atoms/modal/btn-cancel@3x.png"

const Modal = props => {
    const { open, body, width, height, onClickCloseModal, className = "", close = true } = props

    //모달 - 창 닫기
    const onClose = e => {
        if (["rev-modal-background-root", "closeIcon close"].includes(e.target.className)) {
            onClickCloseModal(e)
        }
    }

    return (
        <React.Fragment>
            <div
                className={classnames(`rev-modal-root ${className}`, {
                    open: open
                })}
            >
                <div className="rev-modal-background-root" onClick={e => { onClose(e) }}/>
                <div
                    className="rev-modal-contents"
                    style={{
                        width: width,
                        height: height
                    }}
                >
                    <img className={classnames("closeIcon", {
                        close: close
                    })} src={closeIcon} alt="close" width="18px" height="18px" onClick={e => { onClose(e) }}/>
                    {body}
                </div>
            </div>
        </React.Fragment >
    )

}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,            //모달오픈여부
    onClickCloseModal: PropTypes.func,          //모달닫기를 시도할때 실행할 함수
    width: PropTypes.string.isRequired,         //모달 가로길이
    height: PropTypes.string.isRequired,        //모달 세로길이
    close: PropTypes.bool,                      //오른쪽 상단에 닫기버튼[x] 노출여부
}

export default Modal