
import Request from "./request";

class AuthRequest extends Request {
    /**
     * 해쉬코드 체크 
     * params
     *      hashcode
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    getInfo = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/v2/web/hashcode", request);
    }
    /**
     * 문자인증 요청
     * params
     *      hashcode
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    callAuth = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/v2/web/auth", request);
    }
    /**
     * 문자인증 validation 
     * params
     *      hashcode
     *      inputValidCode
     * 
     * callback 
     *      콜백함수
     * 
     * errorCallback
     *      오류콜백함수
     */
    codeValid = (data, callback, errorCallback) => {
        const request = {
            data,
            callback,
            errorCallback
        };
        this.post("/v2/web/validation", request);
    }

}


export default AuthRequest;
