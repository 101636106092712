import React, { useEffect, useState, useRef } from "react";
import eventManager from "common/components/atoms/utils/eventManager";
import classnames from "classnames";
import "styles/atoms.scss";

let collection = {};
const ACTION = {
    SHOW: 0,
    CLEAR: 1,
    DID_MOUNT: 2,
    WILL_UNMOUNT: 3,
    ON_CHANGE: 4
};
const ToastContainer = () => {
    const [toasts, setToasts] = useState([]);
    const containerRef = useRef();

    useEffect(_ => {
        eventManager.on(ACTION.SHOW, (content, options, delay) =>
            containerRef ? buildToast(content, options, delay) : null
        ).on(ACTION.CLEAR, _id =>
            !containerRef ? null : _id == null ? clear() : removeToast(_id)
        );

        return _ => eventManager.off(ACTION.SHOW).off(ACTION.CLEAR);
    });


    const buildToast = (content, options, delay) => {
        const toastOptions = {
            ...options
        };

        if (Object.keys(collection).length === 0) {
            appendToast(content, toastOptions);
            if (delay !== "N") {
                setTimeout(_ => {
                    removeToast(options._id)
                }, delay)
            };
        }
    };


    const appendToast = (content, options) => {
        const { _id } = options;

        collection = {
            ...collection,
            [_id]: {
                options,
                content
            }
        };
        setToasts(prev => [...prev, _id]);
    };

    const clear = _ => setToasts([]);

    const removeToast = _id => {
        setToasts(prev => prev.filter(p => p !== _id));

        setTimeout(() => {
            delete collection[_id];
        }, 100);

    };

    const renderToast = _ => {
        let toastToRender = [];
        const keys = Object.keys(collection);
        /*
            const images = {
                x: require('img/atoms/toast/button-x@3x.png'),
                warning: require('img/atoms/toast/icon-check@3x.png'),
                error: require('img/atoms/toast/icon-failure@3x.png'),
                info: require('img/atoms/toast/icon-file@3x.png'),
                success: require('img/atoms/toast/icon-success@3x.png'),
                default: require('img/atoms/toast/icon-list-search@3x.png'),
            }
        */

        keys.forEach(k => {
            const { options, content } = collection[k]

            toastToRender.push(
                <div
                    className={classnames("rev-toast-item", {
                        [options.type]: options.type,
                        fadeOut: toasts.indexOf(options._id) === -1
                    }
                    )}
                    {...options}
                    key={`toast-${options._id}`}
                    onClick={e => removeToast(options._id)}
                >
                    <div className={classnames("tit", {
                        msg: content.msg
                    })}>
                        {/* <img className="toastIcon"
                                src={images[options.type].default}
                                width="16px"
                                height="16px"
                                alt="toastIcon"
                            /> */}
                        {content.title}
                        {/* <img className="toastCancelIcon"
                                src={images["x"].default}
                                width="12px"
                                height="12px"
                                alt="toastCancelIcon"
                            /> */}
                    </div>
                    {
                        content.msg &&
                        <div className="con">
                            <span>
                                {content.msg}
                            </span>
                        </div>
                    }
                </div >
            )
        });

        return (
            <React.Fragment>
                {toastToRender}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div ref={containerRef} className="rev-toast-container-root">
                <div className="rev-toast-body">
                    {
                        renderToast()
                    }
                </div>
            </div>
        </React.Fragment >
    );

};
export default ToastContainer;