import eventManager from "common/components/atoms/utils/eventManager";
import { v4 as uuidv4 } from "uuid";

const TYPE = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    DEFAULT: 'default'
};
const ACTION = {
    SHOW: 0,
    CLEAR: 1,
    DID_MOUNT: 2,
    WILL_UNMOUNT: 3,
    ON_CHANGE: 4
};

const generateId = _ => uuidv4();

const usableToastId = _id => (typeof _id === "string") || (typeof _id === "number" && !isNaN(_id)) ? true : false;

const getToastId = options =>
    options &&
        usableToastId(options._id)
        ? options._id
        : generateId();

const mergeOptions = options => ({ ...options, _id: getToastId(options) });

const attachType = (options, type) => mergeOptions({ ...options, type: type });

const dispatchToast = (content, options, delay = 2000) => {
    eventManager.emit(ACTION.SHOW, content, options, delay)
};

const toast = (content, options, delay) => {
    dispatchToast(content,
        mergeOptions(options),
        delay.autoClose
    )
};

toast.error = (content, delay) => dispatchToast(content,
    attachType({}, TYPE.ERROR),
    delay.autoClose
);

toast.info = (content, delay) => dispatchToast(content,
    attachType({}, TYPE.INFO),
    delay.autoClose
);

toast.success = (content, delay) => dispatchToast(content,
    attachType({}, TYPE.SUCCESS),
    delay.autoClose
);

toast.warning = (content, delay) => dispatchToast(content,
    attachType({}, TYPE.WARNING),
    delay.autoClose
);

toast.default = (content, delay) => dispatchToast(content,
    attachType({}, TYPE.DEFAULT),
    delay.autoClose
);

export default toast;