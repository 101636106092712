import * as React from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import classnames from "classnames";
import "styles/atoms.scss";

const CustomButton = React.memo(props => {
    const { classes = "", color = "", labelColor = "", title = "", beforeTitleCompondnt, variant = "", disabled = false, onClick = () => { } } = props;

    const onClickEvent = e => {
        onClick(e);
    };

    return (
        <React.Fragment>
            <Button
                variant={variant}
                className={classnames(`rev-button-root ${variant} ${color} ${classes}`, {
                    disabled: disabled
                })}
                onClick={e => onClickEvent(e)}
            >
                {beforeTitleCompondnt}
                <span className={`button-label ${labelColor}`}>{title}</span>
            </Button>
        </React.Fragment>
    )
});

CustomButton.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary"]),
    labelColor: PropTypes.oneOf(["primary", "secondary", "black", "white"]),
    title: PropTypes.any,
    variant: PropTypes.oneOf(["outlined", "contained"]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};
export default CustomButton;