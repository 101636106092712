import React from "react";
import DefaultLayout from "./default/DefaultLayout";

const ContextLayout = React.createContext()
const Layout = ({ children }) => {

    return (
        <React.Fragment>
            <ContextLayout.Provider
                value={{
                    layout: DefaultLayout
                }}
            >
                {children}
            </ContextLayout.Provider>
        </React.Fragment>
    )
}

export { Layout, ContextLayout }