import Moment from "moment";
import "moment/locale/ko";
import _ from "lodash";

/**
 * 시간 차이 구하는 함수
 * @param {Date} date 비교할 시간
 * @returns {Object} 만약 각 숫자가 - 인경우, 현재날짜및시간이 비교한날짜 혹은 시간이 지난 경우를 의미 (- : 초과한시간, + : 남은시간)
 */
export const calcDiffDate = (date, digit = {
    day: 1,
    hour: 1,
    minute: 1,
    second: 1,
}) => {
    const currentDate = Moment().format();          // 오늘일자
    const compareDate = Moment(date).format();      // 비교할일자
    const c = currentDate;
    const d = compareDate;

    const rs = {
        day: _.padStart(_.floor(Moment.duration(Moment(d).diff(c)).asDays()), digit.day, "0"),
        hour: _.padStart(Moment.duration(Moment(d).diff(c)).hours(), digit.hour, "0"),
        minute: _.padStart(Moment.duration(Moment(d).diff(c)).minutes(), digit.minute, "0"),
        second: _.padStart(Moment.duration(Moment(d).diff(c)).seconds(), digit.second, "0")
    };
    const isOver = rs.day < 0 || rs.hour < 0 || rs.minute < 0 || rs.second < 0;

    return { isOver, ...rs };
};

/**
 * 마스킹 하는 함수
 * @param {String} name 마스킹할 타겟
 * @param {String} type 마스킹할 종류
 * @returns {String} 마스킹완료된 값 반환
 * @memo
 * type === name
 *      리버스랩 -> 리**랩
 * 
 * type === phone
 *      010-0000-0000 -> 010-00**-****
 *      011-000-0000 -> 011-00*-****
 * 
 */
export const mask = (target, type = "name") => {
    const t = String(target);
    let rs = "";

    if (t) {
        if (type === "name") {
            const len = 5;
            const first = t.slice(0, 1);
            const last = t.slice(-1);
            const middle = t.substring(1, t.length - 1);

            if (t.length >= len) {
                rs = first + "..." + last;
            } else {
                rs = first + _.padStart(rs, middle.length, "*") + last;
            }

        } else if (type === "phone") {
            let tmp = t.replaceAll("-", "");
            let size = tmp.length;

            if (size === 11) {
                const n1 = tmp.substring(0, 3);
                const n2 = tmp.substring(3, 5) + "**";
                rs = n1 + "-" + n2 + "-****";

            } else if (size === 10) {
                const n1 = tmp.substring(0, 3);
                const n2 = tmp.substring(3, 5) + "*";
                rs = n1 + "-" + n2 + "-****";
            }
        }

    }


    return rs;
};


/**
 * 마스킹 하는 함수
 * @param {String} name 타겟
 * @param {String} len 말줄임표표시문자길이 기준
 * @returns {String} 말줄임표가 삽입된 문구
 * 
 */
export const ellipsis = (target, len = 13) => {
    const t = String(target);
    let rs = target;

    if (t.length >= len) {
        rs = t.substr(0, len - 2) + "...";
    }

    return rs;
};