const eventManager = {
    list: new Map(),
    emitQueue: new Map(),
    on(event, callback, ...args) {
        this.list.has(event) || this.list.set(event, []);
        this.list.get(event).push(callback);
        return this;
    },
    off(event) {
        this.list.delete(event);
        return this;
    },
    cancelEmit: event => {
        const timers = this.emitQueue.get(event);

        if (timers) {
            timers.forEach(function (timer) {
                return clearTimeout(timer);
            })
            this.emitQueue.delete(event);
        }

        return this;
    },

    emit(event, ...args) {
        const _this = this;

        if (this.list.get(event) !== undefined) {
            this.list.get(event).forEach(callback => {
                var timer = setTimeout(function () {
                    callback.apply(void 0, args)
                }, 0);

                if (!_this.emitQueue.has(event)) {
                    _this.emitQueue.set(event, []);
                };
                _this.emitQueue.get(event).push(timer);
            });
        };

    }

};

export default eventManager;